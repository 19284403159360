import React from "react";

export default function Hero() {
  return (
    <>
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-4 mb-4 text-white">
            Ayuda a Proteger Tu Inversión <br />
            Más Valiosa: Tu Hogar
          </h1>
          <p className="lead mb-4 text-white">
            *Sistema de seguridad Gratis* $850 Después del primer mes
            <br />
            de promoción gratis + los precios mensuales de: $36.99/mes.
          </p>
          <p className="mb-4 text-white">
            *$99.00 Cargos de Instalación del cliente y la compra de los
            servicios
            <br />
            de monitoreo para alarmas. Aplica la tarifa de terminación. Consulte{" "}
            <br />
            los Términos y Condiciones de esta oferta más abajo.
          </p>
          <a
            className="btn btn-primary btn-lg"
            href="tel:855-950-1595"
            role="button"
          >
            LLAMA YA: 855-950-1595
          </a>
        </div>
      </div>
      <div className="border-bottom text-center pb-5 pt-3">
        <h3 className="mb-3">¡Estimado de Seguridad Gratis!</h3>
        <a
          className="btn btn-primary btn-lg"
          href="tel:855-950-1595"
          role="button"
        >
          LLAMA YA: 855-950-1595
        </a>
      </div>
    </>
  );
}
