import React from "react";

// Child Components
import Hero from "../../../components/hero";
import Featured from "../../../components/featured";
import Promo from "../../../components/promo";
import Engage from "../../../components/engage";
import Info from "../../../components/info";

export default function HomePage() {
  return (
    <>
      <Hero />
      <Featured />
      <Promo />
      <Engage />
      <Info />
    </>
  );
}
