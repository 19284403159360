import React from "react";

export default function Info() {
  return (
    <div className="py-5 bg-secondary mb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4 info-box">
            <span className="oi oi-check text-light"></span>
            <p className="text-white font-weight-light">
              Alarmasencasa.com Sistema de Seguridad
            </p>
          </div>
          <div className="col-md-4 info-box">
            <span className="oi oi-headphones text-light"></span>
            <p className="text-white font-weight-light">
              No dudes en llamarnos <br />
              855-950-1595
            </p>
          </div>
          <div className="col-md-4 info-box">
            <span className="oi oi-wrench text-light"></span>
            <p className="text-white font-weight-light">
              Soporte disponible 24 horas / 7 días
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
