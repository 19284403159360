import React from "react";

export default function Header() {
  return (
    <nav className="navbar navbar-light bg-white">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="./assets/img/logo.png" alt="logo" className="img-fluid" />
        </a>
        <div className="navbar-nav ml-auto">
          <a className="btn btn-primary" href="tel:855-950-1595" role="button">
            Llama Ya 855-950-1595
          </a>
        </div>
      </div>
    </nav>
  );
}
