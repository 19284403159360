import React from "react";

export default function Footer() {
  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <small className="text-secondary">
              Licenses: AL-17-1104, AK-35221, AZ-ROC217517, AR-2008-0014,
              CA-ACO6320, CT-ELC.0193944-L5, DE-07-212, FL-EC13003427,
              DC-602513000006, GA-LVA205395, HI-CT30946, ID-ELE-SC-39312,
              IL-127.001042, IN-City of Indianapolis: LAC-000156, IA-AC-0036,
              KY-City of Louisville: 483, LA-F1082, LA-F1914, LA-F1915,
              ME-LM50017382, MD-107-1626, MA-1355C, MI-3601205773, MN-TS01807,
              MS-15007958, MO-City of St. Louis: CC354, St. Louis County: 79853,
              MT-247, NE-14451, NV-68518, City of Las Vegas: 3000002944,
              NJ-34BF00021800, NM-353366, NY-Licensed by the N.Y.S. Dept. of
              State UID#12000317691, #12000286451, NC-1622-CSA, OH-53891446,
              City of Cincinnati: AC86, OK-1048, OR-170997, Pennsylvania Home
              Improvement Contractor Registration Number: PA22999, RI-3582,
              SC-BAC5630, SD-1025-7001-ET, TN-C1520, TX-B13734, ACR-3492,
              UT-6422596-6501, VT-ES-2382, VA-115120, WA-602588694/PROTEYH934RS,
              WV-042433, WI-City of Milwaukee: PAS-0002790, WY-LV-G-21499.
            </small>
            <br />
            <a href="/" data-toggle="modal" data-target="#exampleModal">
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        //@ts-ignore
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Terms and Conditions
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                BASIC SYSTEM: $99 Customer Installation Charge. 36-Month
                Monitoring Agreement required at $36.99 per month ($1,331.64),
                24-Month Monitoring Agreement required at $36.99 ($887.76) for
                California, including Quality Service Plan (QSP). Offer applies
                to homeowners only. Offer valid for new ADT Authorized Premier
                Provider customers only and not on purchases from ADT LLC.
                Cannot be combined with any other offer.
              </p>
              <p>
                GENERAL: For all offers, the form of payment must be by credit
                card or electronic charge to your checking or savings account,
                satisfactory credit history is required and termination fee
                applies. Certain packages require approved landline phone. Local
                permit fees may be required. Certain restrictions may apply.
                Additional monitoring fees required for some services. For
                example, Burglary, Fire, Carbon Monoxide and Emergency Alert
                monitoring requires purchase and/or activation of an ADT
                security system with monitored Burglary, Fire, Carbon Monoxide
                and Emergency Alert devices and are an additional charge.
                Additional equipment may be purchased for an additional charge.
                Additional charges may apply in areas that require guard
                response service for municipal alarm verification. Prices
                subject to change. Prices may vary by market. Some insurance
                companies offer discounts on Homeowner’s Insurance. Please
                consult your insurance company. Photos are for illustrative
                purposes only and may not reflect the exact product/service
                actually provided.
              </p>
              <p>
                In accordance with the FTC guidelines concerning use of
                endorsements and testimonials in advertising, please be aware of
                the following: Testimonials appearing on this site are actually
                received from consumers via text, audio and/or video submission.
                They are the individual experiences, reflecting real life
                experiences of those who have used our products and/or services
                in some way or another. However, they are individual results and
                results do vary. Protect Your Home does not claim that they are
                typical results that consumers will generally achieve. The
                testimonials are not necessarily representative of all of those
                who will use our products and/or services. Testimonials
                displayed (text, audio and/or video) are given verbatim except
                for correction of grammatical or typing errors. Some have been
                shortened. In other words, not the whole message received by the
                testimonial writer is displayed, when it seemed lengthy or not
                the whole testimonial seemed relevant to the general public.
                Protect Your Home is not responsible for any of the opinions or
                comments posted on this site. Protect Your Home does not share
                the opinions, views or commentary of any testimonials on this
                site, which are strictly the views of the reviewer. Protect Your
                Home has not compensated these consumers for their
                testimonials/reviews.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
              >
                I Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
