import React from "react";

export default function Engage() {
  return (
    <>
      <div className="text-center my-5 py-5">
        <h1 className="mb-3 font-weight-light">
          ¿Todavía no estás convencido?
        </h1>
        <h5 className="font-italic text-primary">¿Por qué elegirnos?</h5>
      </div>
      <div className="container mb-5 pb-5">
        <div className="row">
          <div className="col-md-6">
            <div id="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Sistema de Seguridad Monitoreado por ADT Fáciles de Usar
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body text-secondary">
                    El Sistema de Seguridad Monitoreado por ADT para el hogar es
                    fácil de usar. El llavero con control remoto gratis es
                    conveniente cuando se cambia el sistema de encendido o
                    apagado. Cualquier persona en el hogar u oficina puede
                    operar el sistema cuando sea necesario.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      Manténte Interactivo con los Sistemas de Seguridad
                      Controlados por ADT
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Siempre estarás conectado de forma segura a los centros de
                    monitoreo del cliente dentro de los servicios de monitoreo
                    de ADT. Siempre habrá equipos de gran alcance y miembros del
                    personal altamente capacitados para asegurarse de que
                    siempre estés conectado a los sistemas supervisados en todo
                    momento. Si por ejemplo, se produce un evento inusual y un
                    centro de monitoreo se cae, otro lo tomará instantáneamente
                    y mantendrá el servicio a tu hogar u oficina. De esta
                    manera, independientemente de lo que suceda, siempre sabrás
                    que tu casa está siendo monitoreada a todas horas del día y
                    de la noche.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div id="accordion-2">
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      Bajas Tarifas de Monitoreo
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse show"
                  aria-labelledby="headingThree"
                  data-parent="#accordion-2"
                >
                  <div className="card-body text-secondary">
                    Teniendo en cuenta los servicios que reciben los clientes,
                    los honorarios relativos a los sistemas de seguridad son
                    mínimos. Tener un Sistema de Seguridad Monitoreado por ADT
                    en el hogar también puede disminuir tu seguro de
                    propietario.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Nunca temas ni por un segundo
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    Imagínate ser capaz de vivir en tu casa sin tener que
                    preocuparte por los intrusos, ladrones o de otros peligros.
                    En la sociedad actual, es difícil crear un entorno seguro,
                    pero ADT Monitoring puede ayudar a proporcionar a cada
                    cliente con tranquilidad. <br />
                    <br />
                    Si por alguna razón tienes algún problema en los primeros
                    seis meses con el sistema de monitoreo, tú eres elegible
                    para un reembolso de la cuota de instalación y/o el
                    seguimiento de las tasas de conformidad con los términos y
                    condiciones de tu contrato, (bajo ciertas condiciones).
                    <br />
                    <br />
                    Tan pronto como se activa la alarma, un miembro del personal
                    de ADT puede ponerse en contacto contigo, así como la
                    policía, los bomberos o una combinación de los dos. La ayuda
                    entonces te será enviada a tu propiedad para resolver el
                    problema y restaurar tu tranquilidad. Tú puedes confiar en
                    ADT para ayudar a proteger todo lo que posees y para ayudar
                    a mantener tu casa segura donde quieras.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
