import React from "react";

export default function Promo() {
  return (
    <div className="container-fluid">
      <div className="row my-5 bg-gray">
        <div className="col-md-6 half"></div>
        <div className="col-md-6 p-5">
          <div className="p-5">
            <p className="h5 font-weight-light text-secondary">
              ¿Quieres tu nuevo Sistema de Seguridad instalado hoy mismo? Si es
              así, encontrarás un gran trato, ya que Protect Your Home te dará
              un sistema de seguridad monitoreado por ADT, GRATIS* (valorado en
              $850). Además, recibirás instalación completa y comprarás
              servicios de vigilancia por sólo $99 con cero cuotas de
              activación, ¡Es un trato distinto a los demás! ¡Aprovecha ahora
              esta oferta con una tarifa de instalación de $99!
            </p>
            <p className="h5 font-weight-light text-secondary">
              *$99.00 Cargos de Instalación del cliente y y compra de un
              contrato de monitoreo mensual de 24/36 meses. Aplica la tarifa de
              terminación. Consulte los Términos y Condiciones de esta oferta
              más abajo.
            </p>
            <br />
            <p className="h5 font-weight-light text-secondary">
              <small>
                RECIBIRÁS MONITOREO PARA MUCHO MÁS QUE UN ROBO EN SU CASA CON
                SERVICIOS DE SEGUIMIENTO DE ADT. LOS BENEFICIOS QUE GANAS SON
                NUMEROSOS Y TODOS ELLOS VIENEN CON SÓLO UN SISTEMA DE SEGURIDAD
                EN TU CASA.
              </small>
            </p>
            <br />
            <br />
            <p className="h5 font-weight-light text-secondary">
              <small>
                LLAMA EN CUALQUIER MOMENTO 1-866-915-0525 RECLAMA TU SISTEMA DE
                SEGURIDAD PARA EL HOGAR GRATIS
              </small>
            </p>
            <br />
            <p className="h5 font-weight-light text-secondary">
              <small>* ($ 850 DE VALOR)</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
