import React from "react";

export default function Featured() {
  return (
    <>
      <div className="text-center my-5 py-5">
        <h1 className="mb-3 font-weight-light">
          Sistema de Seguridad para el Hogar
        </h1>
        <h5 className="font-italic text-primary">
          ¡Tranquilidad y confianza Garantizada!
        </h5>
      </div>
      <div className="container">
        <div className="row pb-5">
          <div className="col-md-6">
            <div className="h4 font-weight-normal mb-4">
              Siéntete más Tranquilo y en Confianza
            </div>
            <div className="octagon">
              <div className="octagon-inner oi oi-shield"></div>
            </div>
            <p className="m-150 text-secondary pr-4">
              El personal de ADT está totalmente capacitado para hacer frente a
              todos los escenarios e incluso cuando el tiempo es crítico,
              mantienen la calma, con el fin de hacer frente a cualquier
              situación. Los servicios de ADT los distinguen de sus competidores
              y los mantienen como líderes en la industria de la vigilancia de
              la seguridad. El personal sabe cómo ayudar a sus clientes a
              sentirse seguros y tranquilos, para que ellos, también, puedan
              mantener la calma en cualquier tipo de situación. Cuando se trata
              de protección, ninguna compañía puede igualar a ADT – la compañía
              número uno de monitoreo en los Estados Unidos.
            </p>
          </div>
          <div className="col-md-6">
            <div className="h4 font-weight-normal mb-4">
              Líder de la industria de Seguridad Para el Hogar
            </div>
            <div className="octagon">
              <div className="octagon-inner oi oi-box"></div>
            </div>
            <p className="m-150 text-secondary pr-4">
              ADT ha sido el hogar líder de la industria de seguridad durante
              más de 140 años y tiene un largo historial de dedicación y
              eficiencia frente a sus clientes y su protección. Los centros de
              monitoreo de ADT están ubicados en zonas estratégicas para ayudar
              a mantener sus instalaciones vigiladas y protegidas contra la
              violación de la seguridad en todo momento. Los clientes reciben
              numerosas ventajas con ADT Monitoring porque la compañía ha estado
              en el negocio durante tanto tiempo, y ningún otro proveedor puede
              igualar sus promesas y protección. Sistema de Seguridad para el
              Hogar.
            </p>
          </div>
          <div className="col-md-6 mt-5">
            <div className="h4 font-weight-normal mb-4">
              Ayuda a Prevenir Cualquier Actividad Ilegal
            </div>
            <div className="octagon">
              <div className="octagon-inner oi oi-home"></div>
            </div>
            <p className="m-150 text-secondary pr-4">
              Las respuestas de acción rápida proporcionadas por ADT, provienen
              de cuatro centros de vigilancia establecidos en los EE.UU. Estos
              centros están ubicados estratégicamente para que todos los hogares
              supervisados por ADT permanezcan constantemente monitoreados. El
              personal mantiene una estrecha vigilancia sobre los hogares para
              ayudar a detectar y ayudar a prevenir cualquier actividad ilegal.
              Una vez que se active una alarma, el personal inmediatamente
              contactara al departamento de policía o de bomberos más cercano.
              Tan pronto como sea posible, los hogares de los clientes son
              inspeccionados y asegurados una vez más por las autoridades
              competentes.
            </p>
          </div>
          <div className="col-md-6 mt-5">
            <div className="h4 font-weight-normal mb-4">
              ADT Monitoring Ayuda a Proteger tu Hogar
            </div>
            <div className="octagon">
              <div className="octagon-inner oi oi-lock-locked"></div>
            </div>
            <p className="m-150 text-secondary pr-4">
              Al ayudar a proteger tu casa contra incendios, inundaciones,
              ladrones y otros peligros, es muy fácil ver por qué ADT Monitoring
              puede ayudar a mantener tu casa segura, como lo ha hecho durante
              más de un siglo. ADT Monitoring ayuda a proteger tu hogar y ayuda
              a mantenerlo de manera segura las 24 horas del día, los 7 días de
              la semana a partir de todo tipo de emergencias. Sistema de
              Seguridad para el Hogar.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
